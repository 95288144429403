import MyServices from "./MyServices";
import { Account } from "../../api/interfaces/Account";

const processData = (subscriptionsData: any) => {
  const subscriptions = subscriptionsData?.data?.subscription || [];

  return subscriptions.map((subscriptionData: any) => {
    return subscriptionData;
  });
};

interface MyServicesIndexProps {
  currentAccount: Account;
  subscriptionsData: any[];
  isLoadingSubscriptions: boolean;
}

const MyServicesIndex = ({
  isLoadingSubscriptions,
  subscriptionsData,
}: MyServicesIndexProps) => {
  return (
    <MyServices
      isLoadingSubscriptions={isLoadingSubscriptions}
      subscriptions={processData(subscriptionsData)}
    />
  );
};

export default MyServicesIndex;

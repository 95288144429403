import { useContext } from "react";
import { routes } from "../../../../App";
import NavContainer from "../../../../components/NavContainer";
import CardWrapper from "../../../../components/CardWrapper";
import { BackStyle } from "../../Styles";
import { Box, Grid, Typography } from "@mui/material";
import {
  AddressAndAccountContext,
  AddressAndAccountContextType,
} from "../../../../components/AddressAndAccountProvider";
import { OnboardingBubble } from "../../../../assets/icons";
import { VbgTitle, Router, Hardware, ViewMyService } from "./Styles";
import getREMFromPX from "../../../../utils/getREMFromPX";
import useViewport from "../../../../hooks/useViewport";
import RouterIcon from "@mui/icons-material/Router";
import useGetThemePath from "../../../../hooks/useGetThemePath";
import { theme } from "../../../../theme";
import styled from "@emotion/styled";
import { useIsDeviceOn } from "../../../../hooks/useIsDeviceOn";
import sendErrorToast from "../../../../utils/sendErrorToast";

import {
  DeviceFrontFace,
  KontronFrontFace,
  DeviceBackFace,
  KontronBackFace,
} from "../../../../assets/device";

const CheckMarkIcon = styled(OnboardingBubble)`
  width: ${getREMFromPX(40)};
  height: ${getREMFromPX(40)};
  flex-shrink: 0;

  @media (max-width: 820px) {
    margin-top: -8px;
  }

  @media (max-width: 720px) {
    width: ${getREMFromPX(20)};
    height: ${getREMFromPX(20)};
    border: 1px solid black;
    border-radius: 100%;
    margin-top: 0px;
  }
`;

const normalDeviceSteps = [
  "Make sure device is plugged in.",
  "Power button should be blue. If it is red, push until the button is blue.",
  "S2 is the fiber port, these lights should be lit.",
  "Console and USB are not used.",
];

const kortonDeviceSteps = [
  "Make sure device is plugged in.",
  "Power Button \n\nThe power indicator should be solid. If it is not lit, toggle the power switch on the side with the ports. In is on, out is off.",
  "PON and LOS Indicators \n\nPON (Passive Optical Network): If the PON light is flashing, it indicates a normal signal and the LOS light should be off.\n\nLOS (Loss of Signal): If the LOS light is flashing (red LED), it means there is no signal, and the PON light should be off.",
  "LAN Ports\n\n10GE LAN and GE LAN: These lights will illuminate and flash when something is plugged in. The 10GE LAN port might show a yellow light if it's plugged into a 1G port on the other side; if it were 10G capable, it would show green. Typically, subscribers will see yellow since they will be using 1G devices.",
];

const DeviceTroubleshooting = () => {
  const themePath = useGetThemePath();
  const colors = theme[themePath].colors;

  const backAction = (
    <BackStyle aria-label="Back to Help" to={routes.help.path}>
      Back to Help
    </BackStyle>
  );

  const { currentDevice } = useContext(
    AddressAndAccountContext
  ) as AddressAndAccountContextType;

  const { data: isDeviceOn } = useIsDeviceOn(currentDevice?.id, {
    refetchOnWindowFocus: false,
    enabled: Boolean(currentDevice?.id),
    onError: () =>
      sendErrorToast("There was an error getting the device information"),
  });

  const { isMobile } = useViewport();

  const getDeviceFront = () => {
    switch (currentDevice?.Product?.Manufacturer?.name) {
      case "Aaeon":
        return DeviceFrontFace;
      case "Venoen":
        return DeviceFrontFace;
      case "Kontron":
        return KontronFrontFace;
      default:
        return DeviceFrontFace;
    }
  };

  const getDeviceBack = () => {
    switch (currentDevice?.Product?.Manufacturer?.name) {
      case "Aaeon":
        return DeviceBackFace;
      case "Venoen":
        return DeviceBackFace;
      case "Kontron":
        return KontronBackFace;
      default:
        return DeviceBackFace;
    }
  };

  const DeviceFront = getDeviceFront();
  const DeviceBack = getDeviceBack();

  const troubleShootingItems =
    currentDevice?.Product?.Manufacturer?.name === "Kontron"
      ? kortonDeviceSteps
      : normalDeviceSteps;

  return (
    <NavContainer title="Help">
      <CardWrapper title="Troubleshooting" actions={backAction}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <VbgTitle>Having Trouble?</VbgTitle>
          {!isMobile ? (
            <ViewMyService
              to={routes.helpDevice.path}
              aria-label="View Device info"
              sx={{
                display: "block",
              }}
            >
              Device Info
            </ViewMyService>
          ) : null}
        </Box>
        <Typography
          fontSize={isMobile ? getREMFromPX(9.8) : getREMFromPX(16)}
          fontWeight={500}
          color="#000"
          fontFamily="Inter"
          marginRight="5.4px"
          marginLeft={{
            md: "20px",
            xs: "0px",
          }}
          marginTop={{
            md: "17px",
            xs: "8px",
          }}
          display="flex"
          gap={2}
          alignItems="center"
        >
          <Router>
            <RouterIcon fill={colors.icons.default.fillColor} />
          </Router>
          <Hardware>{currentDevice?.hardware_version}</Hardware>
        </Typography>
        <Box
          sx={{
            fontSize: {
              md: "16px",
              xs: "12px",
            },
            fontWeight: 700,
            marginLeft: {
              md: "16px",
              xs: 0,
            },
            mt: 1,
          }}
        >
          Follow these steps below
        </Box>
        {isDeviceOn && isMobile ? (
          <Box
            sx={{
              width: "32px",
              height: "14px",
              borderRadius: "12.3px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: 1,
              backgroundColor:
                isDeviceOn.data.device === "ON" ? "#84e88e" : "#ff7a00",
            }}
          >
            <Typography
              fontFamily="Inter"
              fontSize={isMobile ? getREMFromPX(7.4) : getREMFromPX(12)}
              color={isDeviceOn.data.device === "ON" ? "#0b8718" : "#fff"}
            >
              {isDeviceOn.data.device === "ON" ? "ON" : "OFF"}
            </Typography>
          </Box>
        ) : null}
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="start"
          flexDirection={{
            xs: "column-reverse",
            lg: "row",
          }}
        >
          <Box
            gap={{
              display: "flex",
              flexDirection: "column",
              md: 5,
              xs: 3,
            }}
            mt={{
              md: 4,
              xs: "0px",
            }}
            width={{
              lg: "50%",
              xs: "100%",
            }}
          >
            {troubleShootingItems.map((item, index) => {
              return (
                <Grid
                  item
                  sx={{
                    marginLeft: {
                      md: "16px",
                      xs: 0,
                    },
                    fontWeight: 500,
                    display: "flex",
                    alignItems: "flex-start",
                    gap: 1.5,
                    whiteSpace: "pre-line",
                  }}
                  key={index}
                >
                  <CheckMarkIcon />
                  <Typography
                    mt={{
                      md: 0.8,
                      xs: 0,
                    }}
                  >
                    {item}
                  </Typography>
                </Grid>
              );
            })}
            <Grid
              item
              sx={{
                fontSize: "0.875rem",
                marginRight: "16px",
                marginTop: {
                  md: "126px",
                  xs: "10px",
                },
              }}
            >
              <p>
                Having trouble with your services? Please contact your service
                provider.
              </p>
            </Grid>
          </Box>
          <Box
            sx={{
              zIndex: 2,
              display: "flex",
              justifyContent: "center",
              alignItems: "start",
              mb: "3rem",
            }}
            width={{
              lg: "50%",
              xs: "100%",
            }}
          >
            <Box
              sx={{
                textAlign: {
                  md: "left",
                  xs: "center",
                },
                fontSize: {
                  md: "1.25rem",
                  xs: "1rem",
                },
                fontWeight: "bolder",
                marginTop: {
                  md: 0,
                  xs: 4,
                },
              }}
            >
              <Typography
                component="p"
                sx={{
                  marginBottom: {
                    md: "38px",
                    xs: "16px",
                  },
                  fontWeight: 700,
                  fontSize: {
                    md: "1.25rem",
                    xs: "1rem",
                  },
                }}
              >
                Front
              </Typography>
              <DeviceFront
                style={{
                  marginBottom: "28px",
                }}
              />
              <Typography
                sx={{
                  marginBottom: {
                    md: "38px",
                    xs: "16px",
                  },
                  fontWeight: 700,
                  fontSize: {
                    md: "1.25rem",
                    xs: "1rem",
                  },
                }}
                component="p"
              >
                Back
              </Typography>
              <DeviceBack style={{ marginLeft: getREMFromPX(-8) }} />
            </Box>
          </Box>
        </Box>
      </CardWrapper>
    </NavContainer>
  );
};
export default DeviceTroubleshooting;
